@use "../../../../node_modules/citizen/src/styles/abstracts" as *;
@use "../../../../node_modules/citizen/src/styles/base" as *;

.candidate-exemption-request-modal-wrapper {
  position: fixed;
  inset: 0;
  background-color: var(--color-panel-translucent);
  backdrop-filter: blur(64px) saturate(150%);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
}

.candidate-exemption-request-modal {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 41rem 1fr;
  row-gap: 1.5rem;
  padding-inline: 1rem;
  padding-block: 5rem;

  @media (width < 672px) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-inline: 1rem;
    padding-block: 2rem 4rem;
  }
}

.candidate-exemption-request-modal-header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  grid-column: 2 / 3;
  padding-block-start: 2rem;

  & span {
    color: var(--text-primary);
  }

  & > p {
    color: var(--text-tertiary);
  }

  @include breakpoint(mobile) {
    & h1 {
      font-size: var(--scale3);
      line-height: var(--lh-scale3);
      font-weight: 600;
    }
  }
}

.candidate-exemption-request-modal-header--display-none {
  display: none;
}

.candidate-exemption-request-modal:has(.candidate-exemption-request-modal-header--display-none).candidate-exemption-request-modal {
  padding-block-start: 0;
}

.candidate-exemption-request-modal-main {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  grid-column: 2/3;
}

.candidate-exemption-request-section {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.candidate-exemption-request-section-main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.candidate-exemption-request-type-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  gap: var(--spacing);

  & > * {
    & label {
      height: 100%;
    }
  }

  & .invalid-feedback {
    grid-column: 1/2;
  }
}

.candidate-exemption-request-new-service-date {
  margin-top: 1rem;

  @include breakpoint(md-questionnaire) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing);
  }
}

.candidate-exemption-request-section-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-block-start: var(--spacing);

  & > p {
    color: var(--text-tertiary);
  }
}

.candidate-exemption-request-progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing);
  height: 3.5rem;
  //width: 100vw;
  grid-column: 1 / -1;
  margin-inline: -1rem;
  padding-inline: 1rem;
  border-bottom: 1px solid transparent;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: border 100ms ease-out, background-color 100ms ease-out;

  @media (width < 672px) {
    height: 3rem;
  }
}

.progress-bar-border {
  border-bottom: 1px solid var(--slate-a6);
  background-color: var(--slate-2);
  backdrop-filter: blur(24px);
}

.candidate-exemption-request-progress-bar-inner {
  display: flex;
  width: 41rem;

  .incomplete-text {
    color: var(--text-tertiary);
  }
}

.candidate-exemption-request-progress-step {
  display: flex;
  align-items: center;
  gap: var(--spacing);
  font-size: var(--scale-1);
  font-weight: 500;
}

// Hiding stepper text on narrower screens
.candidate-exemption-request-progress-step-number:not(.status-active) ~ .candidate-exemption-request-progress-step-text {
  @media (width < 600px) {
    display: none;
  }
}

.candidate-exemption-request-progress-step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px dashed var(--text-primary);
  color: var(--text-primary);
  font-size: var(--scale-2);
}

.status {
  &-active {
    border: 1px solid var(--text-primary);
    background: var(--text-primary);
    color: var(--slate-1);
  }

  &-completed {
    & span.candidate-exemption-request-progress-step-number {
      border: 1px solid var(--primary);
      background-color: var(--primary);
      color: var(--white);
    }
  }
}

.candidate-exemption-step-control {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.btn-back {
  margin-inline-end: auto;
}

.btn-next {
  margin-inline-start: auto;
}

.candidate-exemption-request-form-close-btn {
  position: fixed;
  inset: 0.5rem 1rem auto auto;
  border-radius: 1.25rem;
  z-index: 40;
  pointer-events: initial;

  &:hover {
    //background: var(--slate-a11);
  }

  @media (width < 672px) {
    inset-block: auto 0.5rem;
    inset-inline: 0;
    margin-inline: auto;
  }
}

.candidate-exemption-request-error-result-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  margin-block-start: 9.25rem;
  padding-inline: 1rem;
}
